<template>
  <div>
    <div class="profileDiv">
      <div style="border: 1px solid #909399; width: 200px"></div>
      <div
        style="border: 3px solid #303133; width: 300px; margin-top: 3px"
      ></div>
      <div>
        <div class="title">联系我们</div>
        <div class="titleEn">CONTACT US</div>
        <div class="info">
          <div class="info l50">联系电话：13121648382</div>
                <div class="info l50">邮箱：171620024@qq.com</div>
                <div class="info l50">联系地址：浙江省杭州市西湖区转塘街道桐坞村傅家83号401室</div>
        </div>
      </div>
      <div
        style="
          border: 3px solid #303133;
          width: 300px;
          margin-left: 980px;
          margin-top: 30px;
        "
      ></div>
      <div
        style="
          border: 1px solid #909399;
          width: 200px;
          margin-top: 3px;
          margin-left: 1080px;
        "
      ></div>
    </div>
  </div>
</template>

<script>
import * as webApi from '../../../api/webApi'

export default {
  data() {
    return {
      
    };
  },
  methods: {
  },
  created() {
  },
};
</script>

<style scoped>
.profileDiv {
  width: 1280px;
  margin: 0 auto;
  margin-top: 40px;
}
.title,
.titleEn {
  font-size: 26px;
  font-weight: bold;
  text-align: center;
}
.title {
  width: 300px;
  padding-top: 30px;
  line-height: 60px;
  margin: 0 auto;
  border-bottom: 4px solid #f56c6c;
}
.titleEn {
  line-height: 60px;
}
.info {
  margin-top: 40px;
}
.newDiv{
  margin-bottom:40px;
  border:1px solid #303030;
  width:100%;
  height:20vw;
}
.newInfo{
  font-size:16px;
  width:90%;
  margin-left:5%;
  line-height: 25px;
  font-weight: bold;
  margin-top:10px;
  color:#000
}
</style>
